import * as Sentry from '@sentry/nextjs'
import { FormEvent } from 'react'
import { trackCrmPurchase, trackCrmUser } from '../crm-service'
import {
  BrazeCustomEventName,
  BrazePurchaseEventDetails,
  BrazePurchaseProperties,
  BrazeRegisterUserAdditionalFields,
} from './brazeConstants'

export const registerBrazeUserWithEmail = async (
  email: string,
  customEventName: BrazeCustomEventName,
  marketingConsent: boolean,
  additionalFields?: BrazeRegisterUserAdditionalFields,
) => {
  try {
    await trackCrmUser(email, customEventName, marketingConsent, additionalFields)
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Failed to register Braze user with email: ${error.message}`)
    } else {
      throw new Error('Failed to register Braze user with email: unknown error')
    }
  }
}

export const registerBrazeUserWithEmailFromWebForm = async (
  event: FormEvent<HTMLFormElement>,
  email: string,
  customEventName: BrazeCustomEventName,
  callback: (success: boolean, message?: string) => void,
  marketingConsent: boolean,
  additionalFields?: BrazeRegisterUserAdditionalFields,
) => {
  event.preventDefault()

  try {
    await trackCrmUser(email, customEventName, marketingConsent, additionalFields)
    callback(true)
  } catch (error) {
    callback(false, 'Something went wrong - please try again.')
  }
}

const extractNumber = (phrase: string) => {
  const match = phrase.match(/\d+/)
  return match ? parseInt(match[0]) : null
}

// TODO: Payments team to move to backend and make more robust?
export const calculateTotalUnitValue = (purchaseProperties: BrazePurchaseProperties) => {
  // NOTE: total value needs to be the total committed value for the entire bundle purchased
  const testKitPriceDetails = purchaseProperties.filter((item) => item.productCategory === 'Kit')[0]
  const totalTestKitUnitValue = testKitPriceDetails.price * (testKitPriceDetails.productDuration?.instalmentsCount ?? 1)

  const membershipPriceDetails = purchaseProperties.filter((item) => item.productCategory === 'Membership')[0]
  const isMonthlyRollingMembership = membershipPriceDetails.nickname.includes('monthly rolling')
  const monthlyRollingCommitmentPeriod = extractNumber(membershipPriceDetails.nickname) ?? 1
  const totalMembershipUnitValue =
    membershipPriceDetails.price * (isMonthlyRollingMembership ? monthlyRollingCommitmentPeriod : 1)

  return totalTestKitUnitValue + totalMembershipUnitValue
}

export const logBrazePurchaseEvent = async (purchaseDetails: BrazePurchaseEventDetails) => {
  const { orderNumber } = purchaseDetails

  purchaseDetails.price = purchaseDetails.price / 100
  purchaseDetails.currencyCode = purchaseDetails.currencyCode?.toUpperCase()

  try {
    await trackCrmPurchase(purchaseDetails)
  } catch (error) {
    Sentry.captureException(`Braze Purchase Event failed for order ${orderNumber}: ${error.message}`)
  }
}
