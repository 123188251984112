import { AxiosRequestConfig } from 'axios'

export const getBaseConfig = (): AxiosRequestConfig => {
  return {
    responseType: 'json' as const,
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 30 * 1000,
  }
}
